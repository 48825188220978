
import { defineComponent, ref, computed } from 'vue'
import PageBlock from '@/components/layout/PageBlock.vue'
import DetailsHero from '@/components/shared/templates/details/DetailsHero.vue'
import TmAutoSizePanelItemButton from '@/components/shared/autoSize/panel/TmAutoSizePanelItemButton.vue'
import AppearanceStep from '@/components/pages/services/forms/flows/subscribe/AppearanceStep.vue'
import EmbedCodeStep from '@/components/pages/services/forms/flows/subscribe/EmbedCodeStep.vue'
import AdditionalSettingsStep from '@/components/pages/services/forms/flows/clicks/AdditionalSettingsStep.vue'
import TextsStep from '@/components/pages/services/forms/flows/subscribe/TextsStep.vue'
import LandingPageStep from '@/components/pages/services/forms/flows/subscribe/LandingPageStep.vue'
import { useBreakpoints } from '@/compositions/breakpoints'
import SubscribePreview from '@/components/pages/services/forms/flows/subscribe/SubscribePreview.vue'

export default defineComponent({
  components: {
    SubscribePreview,
    LandingPageStep,
    TextsStep,
    TmAutoSizePanelItemButton,
    DetailsHero,
    PageBlock,
    AppearanceStep,
    EmbedCodeStep,
    AdditionalSettingsStep,
  },
  setup(props) {
    const { isXlMax } = useBreakpoints()
    const maxVisibleButtonsComputed = computed(() => isXlMax.value ? 2 : 3)
    const breadcrumbs = [
      { label: 'Forms', name: 'base.services.forms' },
      { label: 'My new form 2023' },
    ]
    const currentStep = ref(1)
    const fields = [
      {
        label: 'Your name',
        placeholder: 'Enter your full name',
        visible: true,
        showVisibility: false,
      },
      {
        label: 'Your mobile number',
        placeholder: 'Enter your mobile number',
        visible: true,
        showVisibility: false,
      },
    ]

    return {
      breadcrumbs,
      fields,
      currentStep,
      maxVisibleButtonsComputed,
    }
  },
})
