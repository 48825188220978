
import { defineComponent } from 'vue'
import TmExpansionWhitePanel from '@/components/shared/tmExpansion/TmExpansionWhitePanel.vue'
import TmTextCopy from '@/components/shared/TmTextCopy.vue'
import TmFormLine from '@/components/shared/TmFormLine.vue'
import TmButton from '@/components/shared/TmButton.vue'
import { codeExample } from '@/definitions/_general/_data/code'

export default defineComponent({
  components: {
    TmButton,
    TmFormLine,
    TmTextCopy,
    TmExpansionWhitePanel,
  },
  setup() {
    return { codeExample }
  },
})
