
import { defineComponent, markRaw, ref } from 'vue'
import TmButton from '@/components/shared/TmButton.vue'
import TmFormLine from '@/components/shared/TmFormLine.vue'
import FlowField from '@/components/pages/services/forms/flows/FlowField.vue'
import TmExpansionWhitePanel from '@/components/shared/tmExpansion/TmExpansionWhitePanel.vue'
import { useModals } from '@/compositions/modals'
import SamplePrivacyContent from '@/components/pages/sample/SamplePrivacyContent.vue'
import SampleTermsContent from '@/components/pages/sample/SampleTermsContent.vue'

export default defineComponent({
  components: {
    TmExpansionWhitePanel,
    TmFormLine,
    TmButton,
    FlowField,
  },
  setup() {
    const isPolicyCreated = ref(false)
    const isTermsCreated = ref(false)
    const { openModal } = useModals()
    const state = ref({
      headerText: 'Subscribe for SMS Alerts',
      description: 'Get deals, sales and other tips sent to your mobile.',
      btnText: 'Subscribe',
      additionalText: 'By submitting your number, you provide express written consent to contact you via SMS. Message frequency varies. Text STOP to opt out. Message and data rates may apply.',
      successMessage: 'Thank you for subscribing!',
      privacy: '',
      smsTerms: '',
      fields: [
        {
          label: 'Your name',
          placeholder: 'Enter your full name',
          visible: true,
          showVisibility: false,
        },
        {
          label: 'Your mobile number',
          placeholder: 'Enter your mobile number',
          visible: true,
          showVisibility: false,
        },
      ],
    })

    const handleCreatePolicy = () => {
      isPolicyCreated.value = true
      state.value.privacy = 'https://app.textmagic.com/widget/subscribe/42d6e2d9a564f33/privacy-policy/'
      openModal('confirmation', {
        title: 'Using the privacy policy template',
        btnText: 'Agree & create',
        size: 'large',
        clickEvent: () => {
          openModal('editSample', {
            title: 'Edit Privacy policy template',
            contentComponent: markRaw(SamplePrivacyContent),
            text: 'Use our sample template to create your own Privacy Policy. We\'ll publish it for you and you will see the URL link to this page in the next step. You can later edit this policy anytime.',
          })
        },
        text: [
          {
            text: 'Please read and acknowledge the following:\n\n',
            style: 'bold',
          },
          'We are pleased to offer a template privacy policy ("Sample Policy") for your SMS Channel, which you can customize if desired.\n\n',
          'By using or accessing this Sample Policy, you agree to the following: The Sample Policy is intended for informational purposes only, does not constitute legal advice, and may not be legally suitable for use in your jurisdiction. You should not rely on or use the Sample Policy or any information contained within it for any purpose without first seeking legal advice from a qualified attorney. Our company expressly disclaims all liability for any actions taken or not taken based on any part of the Sample Policy. For more information, please refer to the Additional Terms for SMS Channel.\n\n',
          {
            text: 'Note:',
            style: 'bold',
          },
          ' This draft template privacy policy does not cover data collection/use for advertising and does not include a cookie policy.',
        ],
      })
    }

    const editPolicy = () => {
      openModal('editSample', {
        title: 'Edit Privacy policy',
        contentComponent: markRaw(SamplePrivacyContent),
        text: 'Use our sample template to create your own Privacy Policy. We\'ll publish it for you and you will see the URL link to this page in the next step. You can later edit this policy anytime.',
      })
    }

    const deletePolicy = () => {
      openModal('confirmation', {
        title: 'Delete privacy policy',
        btnColor: 'error',
        btnText: 'Delete',
        text: 'Are you sure you want to delete this Privacy policy? This action cannot be undone. You will need to use your own Privacy Policy link or create a new one using Textmagic template. ',
      })
    }

    const handleCreateTerms = () => {
      isTermsCreated.value = true
      state.value.smsTerms = 'https://app.textmagic.com/widget/subscribe/42d6e2d9a564f33/terms-of-use/'
      openModal('confirmation', {
        title: 'Using the terms of use template  ',
        btnText: 'Agree & create',
        size: 'large',
        clickEvent: () => {
          openModal('editSample', {
            title: 'Edit Terms of use template',
            contentComponent: markRaw(SampleTermsContent),
            text: 'Use our sample template to create your own Terms of use. We\'ll publish it for you and you will see the URL link to this page in the next step. You can later edit this policy anytime.',
          })
        },
        text: [
          {
            text: 'Please read and acknowledge the following:\n\n',
            style: 'bold',
          },
          'We are pleased to offer a template terms of use ("Sample Terms") for your SMS Channel, which you can customize if desired.\n\n',
          'By using or accessing these Sample Terms, you agree to the following: The Sample Terms are intended for informational purposes only, do not constitute legal advice, and may not be legally suitable for use in your jurisdiction. You should not rely on or use the Sample Terms or any information contained within them for any purpose without first seeking legal advice from a qualified attorney. Our company expressly disclaims all liability for any actions taken or not taken based on any part of the Sample Terms. For more information, please refer to the Additional Terms for SMS Channel.\n\n',
          {
            text: 'Note:',
            style: 'bold',
          },
          ' This draft template terms of use does not cover data collection/use for advertising and does not include a cookie policy.',
        ],
      })
    }

    const editTerms = () => {
      openModal('editSample', {
        title: 'Edit Terms of use',
        contentComponent: markRaw(SampleTermsContent),
        text: 'Use our sample template to create your own Terms of use. We\'ll publish it for you and you will see the URL link to this page in the next step. You can later edit this policy anytime.',
      })
    }

    const deleteTerms = () => {
      openModal('confirmation', {
        title: 'Delete terms of use',
        btnColor: 'error',
        btnText: 'Delete',
        text: 'Are you sure you want to delete this Terms of use? This action cannot be undone. You will need to use your own Terms of use link or create a new one using Textmagic template. ',
      })
    }

    return {
      editTerms,
      deleteTerms,
      deletePolicy,
      editPolicy,
      isTermsCreated,
      isPolicyCreated,
      state,
      handleCreatePolicy,
      handleCreateTerms,
    }
  },
})
