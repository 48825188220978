
import { defineComponent, ref } from 'vue'
import TmFormLine from '@/components/shared/TmFormLine.vue'
import TmExpansionWhitePanel from '@/components/shared/tmExpansion/TmExpansionWhitePanel.vue'

export default defineComponent({
  components: {
    TmExpansionWhitePanel,
    TmFormLine,
  },
  setup() {
    const state = ref({
      headerText: 'Subscribe for SMS Alerts',
      description: 'Get deals, sales and other tips sent to your mobile.',
      btnText: 'Subscribe',
      additionalText: 'You can later unsubscribe simply by texting STOP.',
      theme: 'Blue theme',
      font: 'Arial',
      successMessage: 'Thank you for subscribing!',
      headerColor: '#008CFF',
      numberColor: '#008CFF',
      buttonColor: '#008CFF',
      height: '400',
      width: '300',
      fields: [
        {
          label: 'Your name',
          placeholder: 'Enter your full name',
          visible: true,
          showVisibility: false,
        },
        {
          label: 'Your mobile number',
          placeholder: 'Enter your mobile number',
          visible: true,
          showVisibility: false,
        },
      ],
    })
    const themeOptions = ref<string[]>([
      'Blue theme',
      'Brown theme',
      'Red theme',
      'Black theme',
      'White theme',
    ])
    const fontList = ref<string[]>([
      'Arial',
      'Helvetica',
      'Roboto',
    ])
    return {
      themeOptions,
      fontList,
      state,
    }
  },
})
