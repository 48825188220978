
import { defineComponent, ref } from 'vue'
import TmFormLine from '@/components/shared/TmFormLine.vue'
import TmExpansionWhitePanel from '@/components/shared/tmExpansion/TmExpansionWhitePanel.vue'
import TmButton from '@/components/shared/TmButton.vue'

export default defineComponent({
  components: {
    TmButton,
    TmExpansionWhitePanel,
    TmFormLine,
  },
  setup() {
    const url = ref('https://textmagic.com/forms/foR5kdZ')

    return {
      url,
    }
  },
})
