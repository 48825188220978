
import type { PropType } from 'vue'
import { defineComponent, computed } from 'vue'
import TmFormLine from '@/components/shared/TmFormLine.vue'
import TmButton from '@/components/shared/TmButton.vue'
import type { FlowFieldType } from '@/definitions/services/forms/types'

export default defineComponent({
  components: {
    TmButton,
    TmFormLine,
  },
  props: {
    headerText: {
      type: String,
    },
    fields: {
      type: Array as PropType<FlowFieldType[]>,
      required: true,
    },
    description: {
      type: String,
    },
    btnText: {
      type: String,
    },
    additionalText: {
      type: String,
    },
  },
  setup(props) {
    const fieldsComputed = computed(() => props.fields.filter((e: FlowFieldType) => e.visible))

    return {
      fieldsComputed,
    }
  },
})
